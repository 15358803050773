<nb-card class="h-100 card-row">
  <nb-card-body class="m-0">
    <div>
      <img [src]="imageUrl" [alt]="altText" draggable="false" />

      <!-- src="baseHref + 'images/auth/angular.png'"  -->
    </div>
    <div class="main-wrapper">
      <div>
        <div class="sign-in-title">
          <h2>Reset Password</h2>
          <p>
            Please Enter and confirm your new password
            <span>{{ reqEmail }}</span> for to complete the password reset
            process
          </p>
        </div>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div>
            <div class="input-title">
              <span>New Password</span>
            </div>
            <div class="input">
              <input
                nbInput
                fullWidth
                [type]="getInputTypeShowPassword()"
                formControlName="password"
                placeholder="Enter New Password"
              />
              <nb-icon
                [icon]="showPassword ? 'eye-outline' : 'eye-off-outline'"
                pack="eva"
                nbSuffix
                (click)="toggleShowPassword()"
                class="cursor-pointer password-icon"
              >
              </nb-icon>
            </div>
          </div>
          <!-- mandatory msg -->
          <div
            class="error-message"
            *ngIf="resetPasswordForm.get('password').hasError('pattern')"
          >
            {{
              'Password must be strong(one uppercase letter, one lowercase letter, one special character, and at least a digits with a minimum length of 8 characters)'
            }}
          </div>

          <!-- confirm password  -->
          <div>
            <div class="input-title">
              <span>Confirm Password</span>
            </div>
            <div class="input">
              <input
                nbInput
                fullWidth
                [type]="getInputTypeConfirmShowPassword()"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
              />
              <nb-icon
                [icon]="showConfirmPassword ? 'eye-outline' : 'eye-off-outline'"
                pack="eva"
                nbSuffix
                (click)="toggleShowConfirmPassword()"
                class="cursor-pointer password-icon"
              >
              </nb-icon>
            </div>
          </div>

          <!-- match msg -->
          <div
            class="error-message"
            *ngIf="
              resetPasswordForm.hasError('mismatch') &&
              resetPasswordForm.get('confirmPassword').touched
            "
          >
            {{ 'Password and confirm password does not match' }}
          </div>
          <!-- submit button -->
          <div>
            <div class="loginbtn">
              <button
                nbButton
                shape="round"
                size="medium"
                type="submit"
                status="basic"
              >
                Reset Password
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </nb-card-body>
</nb-card>
