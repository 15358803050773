export const environment = {
  production: false,
  clientId: 'patient_app',
  publicKey: 'TrPbi0xJT',
  homePath: '/main/home',
  baseApiUrl: '',
  authServiceUrl: 'https://api-dev.ogenome.link',
  userServiceUrl: 'https://api-dev.ogenome.link',
  wellnessServiceUrl: 'https://api-dev.ogenome.link',
  logLevel: 5,
};
