<nb-card class="h-100 card-row">
  <nb-card-body class="m-0">
    <div>
      <img [src]="imageUrl" [alt]="altText" draggable="false" />
    </div>
    <!-- Entery point  -->
    <div class="main-wrapper">
      <div>
        <div class="sign-in-title">
          <h2>Reset Password</h2>
          <p>
            Please Enter your Email address below to receive a link for your
            resetting Password
          </p>
        </div>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
          <!-- Email input -->
          <div>
            <div class="input-title">
              <span>Email</span>
            </div>
            <div class="input">
              <input
                type="text"
                placeholder="Email"
                nbInput
                fieldSize="medium"
                formControlName="email"
              />
            </div>
            <div
              *ngIf="
                forgotPasswordForm.get('email').hasError('required') &&
                forgotPasswordForm.get('email').touched
              "
            >
              Email is required.
            </div>
            <div
              *ngIf="
                forgotPasswordForm.get('email').hasError('email') &&
                forgotPasswordForm.get('email').touched
              "
            >
              Invalid email format.
            </div>
          </div>
          <!-- submit button -->
          <div class="loginbtn">
            <button
              nbButton
              shape="round"
              size="medium"
              type="submit"
              status="basic"
            >
              Send Link
            </button>
          </div>
        </form>
      </div>
    </div>
  </nb-card-body>
</nb-card>
